.bg-my {
    background-color: #474B4F;
    /* font-size: calc(13px + 0.3vw); */
    font-size: 1em;
    font-family: "Poppins";
    font-weight: 400;
}

.navbar-brand {
    padding-top: 0%;
    padding-bottom: 0%;
}

.mbg {
    background-color: #222629;
    color: #63B232;
    font-family: "Poppins";
    font-weight: 200;
    font-size: calc(13px + 0.3vw);
    align-items: center;
    text-align: justify;
    padding: 0%;
}

.mbg hr {
    color: #fff;
    opacity: 1;
    padding-top: 2px;
    padding-bottom: 2px;
}

.mbg p {
    color: #fff;
}

.ptop {
    padding-top: 100px;
}

.ptop2 {
    padding-top: 150px;
}


.mlink {
    color: #63B232;
    font-weight: 600;
}

.mlink a {
    color: #63B232;
}

.mlink a:hover {
    color: #81e245;
    background-color: #474B4F;
}

.dropdown-menu {
    background-color: #474B4F;
    text-align: center;
}

.dropdown-item.active {
    color: #81e245;
    background-color: #474B4F;
}

.navbar-nav .nav-link.active {
    color: #81e245;

}

.navbar-nav {
    align-items: center;
}

.nav-link:focus {
    color: #81e245;
}

.navbar-nav .show .nav-link.active {
    color: #81e245;
    text-align: center;
}

.navbar-nav .show .nav-link {
    color: #63B232;
    text-align: center;
}

.navbar-toggler {
    background-color: #63B232;
    padding: 5px;
}

.par1 {
    padding: 0;
}

.mbtn {
    background-color: #61892F;
    --bs-btn-hover-bg: #63B232;
    --bs-btn-border-color: #fff;
    --bs-btn-hover-border-color: #222629;
}

.mblink a {
    color: #fff;
    padding-top: 0.8em;
}

.bg-my h5 {
    color: #fff;
}

.mif {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    max-height: 350px;
}

.mvid {
    position: relative;
    width: 100%;
    overflow: hidden;
    /* padding-top: 56.25%; 16:9 Aspect Ratio */
    padding-top: 350px;
    max-width: 700px;
    /* max-height: 350px; */
    /* height: 350px; */
    margin: auto;
}

.d-flex {
    justify-content: center;
}

.row {
    justify-content: center;
}

.mw {
    color: #fff;
}

.mp {
    padding: 0.5em;
    font-size: larger;
}

.mp a {
    color: #fff;
}

.dist {
    height: 11vw;
}

.menuButton {
    border: solid 1px;
    padding: 0px;
}

.dropdown-menu {
    --bs-dropdown-min-width: 5rem;
}

.nowr {
    white-space: nowrap;
}